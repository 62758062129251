import type { GetServerSideProps, GetServerSidePropsContext } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head'
import useSWR from 'swr'

import { ProjectsAPI } from '@miimosa/api'
import nextFetcher from '@miimosa/api/nextFetcher'
import { v3toFrontSearchProject, s3ImageURL } from '@miimosa/common'
import type { FrontSearchProjectType, VoteResult } from '@miimosa/common/types'

import { Hero, TwoProjects, TwoYears } from '@components'

const getVotableProjects = async (): Promise<FrontSearchProjectType[]> => {
  const projectsAPI = new ProjectsAPI()

  try {
    const results = await projectsAPI.getProjectsFromSearch({
      query: '',
      type_filter: 'PROJECT_TYPE_UNSPECIFIED',
      categories_filter: [],
      around_me_filter: undefined,
      pagination: undefined,
      state_filter: ['PROJECT_STATE_FUNDED', 'PROJECT_STATE_PUBLISHED'],
      regional_code_filter: [],
      preset: 'PRESET_UNSPECIFIED',
      legacy_pagination: {
        items_per_page: 5,
        page: 1,
      },
      campaign_filter: [Number(process.env.NEXT_PUBLIC_INNO_OPE_2024)],
      labels_filter: [],
      branch_code: `mii_fr`,
      locale: 'fr',
      collect_type_filter: 'COLLECT_TYPE_UNSPECIFIED',
      partner_filter: [],
      collect_goal_type_filter: ['COLLECT_GOAL_TYPE_AMOUNT', 'COLLECT_GOAL_TYPE_QUANTITY'],
      slug_filter: [],
    })

    return results.kind === 'success' ? results.data.projects.map(v3toFrontSearchProject) : []
  } catch (err) {
    return []
  }
}

export const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
  return {
    props: {
      ...(await serverSideTranslations(context.locale || 'fr', ['common'])),
      votableProjects: await getVotableProjects(),
    },
  }
}

const Home = ({ votableProjects }: { votableProjects: FrontSearchProjectType[] }) => {
  const { data: votes } = useSWR<VoteResult>('/nextapi/vote', nextFetcher, {
    fallbackData: { total_vote_count: 0, projects: [] },
  })
  //
  // const { displayQRCodeMode } = useQRCode()
  // const { totalAmount } = useProjectVote(qrCodeActive, consumeQRCode)

  // TODO USE NEW 2023 TOTAL COLLECTED !!!!!!!!!!!!
  let projectsAmount = 0
  votableProjects.forEach((project) => (projectsAmount += parseFloat(String(project?.collect?.collectedAmount || 0))))
  // completed.forEach((project) => (projectsAmount += parseFloat(project['collected'])))

  // const globalAmount = totalAmount + projectsAmount

  return (
    <>
      <Head>
        <title>Rejoignez la grande classe verte</title>
        <meta property="og:title" content="Rejoignez la grande classe verte"></meta>
        <meta
          property="og:description"
          content="La grande classe verte revient pour une deuxième édition. Plein de nouveaux projets de revégétalisation à découvrir !"
        ></meta>
        <meta property="og:image" content={s3ImageURL('meta_image.jpg')}></meta>
      </Head>
      <Hero />
      <TwoProjects projects={votableProjects} votes={votes?.projects ?? []} />
      <TwoYears />
    </>
  )
}

export default Home
